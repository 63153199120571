/**
 * @generated SignedSource<<eb9af7203a2c468693776af7717a7d11>>
 * @relayHash 78cca1b57a20781b7252a2457a68dee3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/479.0.0-2024-09-12T11:58:40.384Z/checkHomepageGroupQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type HomepageHoldoutGroup = "consumer" | "control" | "trade" | "%future added value";
export type checkHomepageGroupQuery$variables = {
  userId: string;
};
export type checkHomepageGroupQuery$data = {
  readonly viewer: {
    readonly homepageUserGroup: HomepageHoldoutGroup | null;
  };
};
export type checkHomepageGroupQuery = {
  response: checkHomepageGroupQuery$data;
  variables: checkHomepageGroupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "userId",
      "variableName": "userId"
    }
  ],
  "kind": "ScalarField",
  "name": "homepageUserGroup",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkHomepageGroupQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkHomepageGroupQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/479.0.0-2024-09-12T11:58:40.384Z/checkHomepageGroupQuery",
    "metadata": {},
    "name": "checkHomepageGroupQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8bc0cf80fe04a4f358a683e4134ac179";

export default node;
